import { generatePath } from '.';

export enum URL_RESOURCE_TYPE {
  xrc = 'xrc',
  mr = 'mr',
  xr = 'xr',
  crd = 'crd',
  resource = 'resource',
  configuration = 'configuration',
  composition = 'composition',
  xrd = 'xrd',
  function = 'function',
  provider = 'provider',
  revision = 'revision',
}

type ResType = string & keyof typeof URL_RESOURCE_TYPE;
export type BackupType = 'configurations' | 'jobs' | 'schedules';
export type SecretType = string & ('sharedSecret' | 'sharedSecretStore');

/**
 * ADMINISTRATION
 */

export const administrationIndex = {
  url(account: string) {
    return generatePath(administrationIndex.route, { account });
  },
  route: '/:account/administration' as const,
};

export const administrationRoutes = [administrationIndex.route];

/**
 * SPACES
 */

export const spacesIndex = {
  url(account: string) {
    return generatePath(spacesIndex.route, { account });
  },
  route: '/:account/spaces' as const,
};

export const spaceView = {
  url(account: string, spaceName: string) {
    return generatePath(spaceView.route, { account, spaceName });
  },
  route: '/:account/spaces/:spaceName' as const,
};

export const attachSpace = {
  url(account: string) {
    return generatePath(attachSpace.route, { account });
  },
  route: '/:account/spaces/attach' as const,
};

export const spaceRoutes = [spacesIndex.route, spaceView.route, attachSpace.route];

/**
 * SPACE GROUPS
 */

export const spaceGroupView = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupView.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName' as const,
};

export const spaceGroupCreate = {
  url(account: string, spaceName: string) {
    return generatePath(spaceGroupCreate.route, { account, spaceName });
  },
  route: '/:account/spaces/:spaceName/groups/create' as const,
};

export const spaceGroupControlPlanesList = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupControlPlanesList.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes' as const,
};

/**
 * SPACE GROUP SECRETS
 */
export const spaceGroupSecrets = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupSecrets.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/secrets' as const,
};

export const spaceGroupSecretsType = {
  url(account: string, spaceName: string, groupName: string, secretType: SecretType = 'sharedSecretStore') {
    return generatePath(spaceGroupSecretsType.route, { account, spaceName, groupName, secretType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/secrets/:secretType' as const,
};

export const spaceGroupSecretsTypeDetails = {
  url(account: string, spaceName: string, groupName: string, secretType: SecretType, secretId: string) {
    return generatePath(spaceGroupSecretsTypeDetails.route, { account, spaceName, groupName, secretType, secretId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/secrets/:secretType/:secretId' as const,
};

export const spaceGroupSecretsTypeCreate = {
  url(account: string, spaceName: string, groupName: string, secretType: SecretType) {
    return generatePath(spaceGroupSecretsTypeCreate.route, { account, spaceName, groupName, secretType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/secrets/:secretType/create' as const,
};

/**
 * END SPACE GROUP SECRETS
 */

export const spaceGroupPolicy = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupPolicy.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/policy' as const,
};

export const spaceGroupObservability = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupObservability.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/observability' as const,
};

export const spaceGroupSharedTelemetryConfigDetails = {
  url(account: string, spaceName: string, groupName: string, sharedTelemetryConfigId: string) {
    return generatePath(spaceGroupSharedTelemetryConfigDetails.route, {
      account,
      spaceName,
      groupName,
      sharedTelemetryConfigId,
    });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/observability/:sharedTelemetryConfigId' as const,
};

/**
 * SPACE GROUP BACKUPS
 */
export const spaceGroupBackup = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupBackup.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/backups' as const,
};

export const spaceGroupBackupType = {
  url(account: string, spaceName: string, groupName: string, backupType: BackupType = 'jobs') {
    return generatePath(spaceGroupBackupType.route, { account, spaceName, groupName, backupType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/backups/:backupType' as const,
};

export const spaceGroupBackupTypeDetails = {
  url(account: string, spaceName: string, groupName: string, backupType: BackupType, backupId: string) {
    return generatePath(spaceGroupBackupTypeDetails.route, { account, spaceName, groupName, backupType, backupId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/backups/:backupType/:backupId' as const,
};

export const spaceGroupBackupTypeCreate = {
  url(account: string, spaceName: string, groupName: string, backupType: BackupType) {
    return generatePath(spaceGroupBackupTypeCreate.route, { account, spaceName, groupName, backupType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/backups/:backupType/create' as const,
};

export const spaceGroupBackupTypeRestore = {
  url(account: string, spaceName: string, groupName: string, backupType: BackupType, backupId: string) {
    return generatePath(spaceGroupBackupTypeRestore.route, { account, spaceName, groupName, backupType, backupId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/backups/:backupType/:backupId/restore' as const,
};
/**
 * END SPACE GROUP BACKUPS
 */

export const spaceGroupSettings = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupSettings.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/settings' as const,
};

// Space Group Control Plane

export const spaceGroupCpView = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(spaceGroupCpView.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId' as const,
};

export const spaceGroupCpProviderView = {
  url(account: string, spaceName: string, groupName: string, cpId: string, providerId: string) {
    return generatePath(spaceGroupCpProviderView.route, { account, spaceName, groupName, cpId, providerId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/providers/:providerId' as const,
};

export const spaceGroupCpResourcesList = {
  url(account: string, spaceName: string, groupName: string, cpId: string, resType: ResType) {
    return generatePath(spaceGroupCpResourcesList.route, { account, spaceName, groupName, cpId, resType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/resources/:resType' as const,
};

export const spaceGroupCpResourceView = {
  url(account: string, spaceName: string, groupName: string, cpId: string, resType: ResType, resId: string) {
    return generatePath(spaceGroupCpResourceView.route, { account, spaceName, groupName, cpId, resType, resId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/resources/:resType/:resId' as const,
};

export const spaceGroupCpEvents = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(spaceGroupCpEvents.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/events' as const,
};

export const spaceGroupCpSecrets = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(spaceGroupCpSecrets.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/secrets' as const,
};

export const spaceGroupCpAddOns = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(spaceGroupCpAddOns.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/integrations' as const,
};

export const spaceGroupCpSettings = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(spaceGroupCpSettings.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/settings' as const,
};

export const spaceGroupCpCRD = {
  url(account: string, spaceName: string, groupName: string, cpId: string, crdId: string) {
    return generatePath(spaceGroupCpCRD.route, { account, spaceName, groupName, cpId, crdId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/definitions/:crdId' as const,
};

export const spaceGroupCpCreateProviderConfigInstance = {
  url(account: string, spaceName: string, groupName: string, cpId: string, crdId: string, providerId: string) {
    return generatePath(spaceGroupCpCreateProviderConfigInstance.route, {
      account,
      spaceName,
      groupName,
      cpId,
      crdId,
      providerId,
    });
  },
  route:
    '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/providers/:providerId/providerConfigs/:crdId/create' as const,
};

export const spaceGroupCpEditProviderConfigInstance = {
  // eslint-disable-next-line max-len
  url(
    account: string,
    spaceName: string,
    groupName: string,
    cpId: string,
    crdId: string,
    id: string,
    providerId: string,
  ) {
    return generatePath(spaceGroupCpEditProviderConfigInstance.route, {
      account,
      spaceName,
      groupName,
      cpId,
      crdId,
      id,
      providerId,
    });
  },
  route:
    '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/providers/:providerId/providerConfigs/:crdId/edit/:id' as const,
};

export enum SpaceGroupCpConfigurationType {
  provider = 'provider',
  configuration = 'configuration',
  function = 'function',
  composition = 'composition',
  xrd = 'xrd',
}

export const spaceGroupCpConfigurationList = {
  url(account: string, spaceName: string, groupName: string, cpId: string, configType: SpaceGroupCpConfigurationType) {
    return generatePath(spaceGroupCpConfigurationList.route, { account, spaceName, groupName, cpId, configType });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/configurations/:configType' as const,
};

export const spaceGroupCpCreate = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupCpCreate.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/create' as const,
};

export const spaceGroupCpCreateSelectConfig = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupCpCreateSelectConfig.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/create/selectConfig' as const,
};

export const spaceGroupCpCreateDetails = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(spaceGroupCpCreateDetails.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/create/details' as const,
};

export const spaceGroupRoutes = [
  spaceGroupView.route,
  spaceGroupControlPlanesList.route,
  // secrets
  spaceGroupSecrets.route,
  spaceGroupSecretsType.route,
  spaceGroupSecretsTypeDetails.route,
  spaceGroupSecretsTypeCreate.route,
  spaceGroupPolicy.route,
  spaceGroupBackup.route,
  spaceGroupBackupType.route,
  spaceGroupBackupTypeDetails.route,
  spaceGroupBackupTypeCreate.route,
  spaceGroupBackupTypeRestore.route,
  spaceGroupSettings.route,
  spaceGroupCreate.route,
  // Space Group Control Plane
  spaceGroupCpView.route,
  spaceGroupCpProviderView.route,
  spaceGroupCpResourcesList.route,
  spaceGroupCpEvents.route,
  spaceGroupCpAddOns.route,
  spaceGroupCpSettings.route,
  spaceGroupCpCRD.route,
  spaceGroupCpCreateProviderConfigInstance.route,
  spaceGroupCpEditProviderConfigInstance.route,
  spaceGroupCpCreate.route,
  spaceGroupCpCreateSelectConfig.route,
  spaceGroupCpCreateDetails.route,
];

export const createConfiguration = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(createConfiguration.route, { account, spaceName, groupName });
  },
  route: `/:account/spaces/:spaceName/groups/:groupName/configurations/create` as const,
};

export const createConfigRoutes = [createConfiguration.route];

export const configList = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(configList.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/configurations' as const,
};

export const configView = {
  url(account: string, spaceName: string, groupName: string, configurationId: string) {
    return generatePath(configView.route, { account, spaceName, groupName, configurationId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/configurations/:configurationId' as const,
};

export const configRoutes = [configView.route, ...createConfigRoutes];

export const controlPlaneAddTeams = {
  url(account: string, spaceName: string, groupName: string, cpId: string) {
    return generatePath(controlPlaneAddTeams.route, { account, spaceName, groupName, cpId });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/controlPlanes/:cpId/settings/addTeams' as const,
};

export const groupsAddTeams = {
  url(account: string, spaceName: string, groupName: string) {
    return generatePath(groupsAddTeams.route, { account, spaceName, groupName });
  },
  route: '/:account/spaces/:spaceName/groups/:groupName/settings/addTeams' as const,
};
