import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as styledSystem from 'styled-system';
import { fontConsolas } from 'upbound-frontend-constants';

import { COLORS, shouldForwardProp } from '../constants/styles';

const baseStyle = css`
  ${fontConsolas};
  color: ${COLORS.darkGreyBlue};
  background-color: ${COLORS.lightBlueGrey};
  font-size: 12px;
  line-height: 20px;
  white-space: pre-line;
  border-radius: 5px;
  padding: 5px 5px 5px 5px;
`;

interface CodeProps
  extends React.HTMLAttributes<HTMLElement>,
    styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);

export const Code = styled('code', { shouldForwardProp })<CodeProps>`
  ${baseStyle}
  ${baseStyledSystem}
`;
