import { ControlPlaneConfigurationProvider } from '@/generated/upbound-graphql';
import { getAccountsURL, getBaseDomain } from '@/utils/config';

//#region Crossplane
export const crossplaneSlack = { url: () => 'https://slack.crossplane.io' };
//#endregion

//#region Marketing
export const marketingHome = { url: () => 'https://www.upbound.io' };
export const marketingFaq = { url: () => 'https://www.upbound.io/faq' };
//#endregion

//#region Upbound
export const upboundStatus = { url: () => 'https://status.upbound.io' };
export const contactUs = { url: () => 'https://www.upbound.io/contact' };
//#endregion

//#region Docs
export const docsHome = { url: () => 'https://docs.upbound.io' };
export const docsCli = { url: () => 'https://docs.upbound.io/reference/cli/' };
export const docsMcpManagement = { url: () => 'https://docs.upbound.io/mcp/#management' };
export const docsQuickStart = { url: () => 'https://docs.upbound.io/quickstart/' };
export const docsMcpConnector = { url: () => 'https://docs.upbound.io/mcp/ctp-connector/' };
export const docsMcpOIDC = { url: () => 'https://docs.upbound.io/mcp/oidc/' };
export const docsCtpPortal = { url: () => 'https://docs.upbound.io/console/ctp-portal/' };
export const docsSpaceAttachDetach = { url: () => 'https://docs.upbound.io/all-spaces/spaces/attach-detach/' };
export const docsSharedSecretStores = {
  url: () => 'https://docs.upbound.io/all-spaces/secrets-management/#configure-a-shared-secret-store',
};
export const docsSharedExternalSecrets = {
  url: () => 'https://docs.upbound.io/all-spaces/secrets-management/#configure-a-shared-external-secret',
};
export const docsBackups = {
  url: () => 'https://docs.upbound.io/all-spaces/backup-and-restore/#configure-a-shared-backup',
};
export const docsScheduledBackups = {
  url: () => 'https://docs.upbound.io/all-spaces/backup-and-restore/#configure-a-shared-backup-schedule',
};
export const docsBackupConfigs = {
  url: () => 'https://docs.upbound.io/all-spaces/backup-and-restore/#configure-a-shared-backup-config',
};
export const docsProviders = { url: () => 'https://docs.upbound.io/providers/' };
export const docsConfigurations = { url: () => 'https://docs.upbound.io/upbound-marketplace/packages/' };
export const docsProviderConfigs = {
  url: () => 'https://docs.upbound.io/reference/cli/command-reference/#controlplane-kubeconfig-get',
};
export const docsGitops = { url: () => 'https://docs.upbound.io/mcp/gitops' };
export const docsObservability = { url: () => 'https://docs.upbound.io/all-spaces/observability/' };
//#endregion

//#region Marketplace
export const marketplaceBrowseConfigurations = {
  route: '/configurations' as const,
  url: () => `https://marketplace.${getBaseDomain()}/configurations`,
};
export const marketplaceBrowseProviders = {
  route: '/providers' as const,
  url: () => `https://marketplace.${getBaseDomain()}/providers`,
};
export const marketplaceBrowseFunctions = {
  route: '/functions' as const,
  url: () => `https://marketplace.${getBaseDomain()}/functions`,
};
//#endregion

//#region Accounts
export const accountsUrl = (path?: string | undefined, returnTo?: string | undefined): string =>
  `${getAccountsURL()}${path}?targetProperty=console${returnTo ? `&returnTo=${encodeURIComponent(returnTo)}` : ''}`;
export const accountsLogin = {
  route: '/login' as const,
  url: (returnTo?: string) => accountsUrl('/login', returnTo),
};
export const accountsLogout = {
  route: '/logout' as const,
  url: (returnTo?: string) => accountsUrl('/logout', returnTo),
};
export const accountsSettings = {
  route: '/account/settings/:subpage*' as const,
  url: (subpage?: string, returnTo?: string) => accountsUrl(`/settings${subpage ? `/${subpage}` : ''}`, returnTo),
};
export const accountsTeams = {
  route: '/o/:orgName/teams' as const,
  url: (orgName: string, returnTo?: string) => accountsUrl(`/o/${encodeURIComponent(orgName)}/teams`, returnTo),
};
export const accountsRobots = {
  route: '/o/:orgName/robots' as const,
  url: (orgName: string, returnTo?: string) => accountsUrl(`/o/${encodeURIComponent(orgName)}/robots`, returnTo),
};
export const accountsOrgMembers = {
  route: '/o/:orgName/users' as const,
  url: (orgName: string, returnTo?: string) => accountsUrl(`/o/${encodeURIComponent(orgName)}/users`, returnTo),
};
//#endregion

//#region GettingStartedAuth
// auth url which takes control over the repo auth flow
export const gettingStartedAuth = {
  url: (provider: ControlPlaneConfigurationProvider = ControlPlaneConfigurationProvider.Github) => {
    return `https://api.${getBaseDomain()}/v1/gitSources/${provider.toLowerCase()}/client/login`;
  },
};
// url that handles the postMessage logic (as a final redirect_uri to actual api auth flow)
export const gettingStartedAuthCallback = {
  url: () => {
    return `https://console.${getBaseDomain()}/repoAuth.html`;
  },
};
//#endregion
