import { css } from '@emotion/react';
import styled from '@emotion/styled';
import * as styledSystem from 'styled-system';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { COLORS, shouldForwardProp } from '../constants/styles';

const baseStyle = (isBold: boolean) => css`
  ${isBold ? fontAvenirBold : fontAvenir};
  font-size: 14px;
  line-height: 20px;
`;

const smallStyle = css`
  font-size: 12px;
  line-height: 20px;
`;

const largeStyle = css`
  font-size: 16px;
  line-height: 30px;
`;

const extraLargeStyle = css`
  font-size: 18px;
  line-height: 40px;
`;

const styleMap = {
  small: smallStyle,
  large: largeStyle,
  extraLarge: extraLargeStyle,
};

interface ParagraphProps
  extends styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS;
  size?: keyof typeof styleMap;
  bold?: boolean;
  textOverflow?: 'clip' | 'ellipsis' | 'inherit' | 'initial' | 'revert' | 'revert-layer' | 'unset';
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
  styledSystem.system({ textOverflow: true, whiteSpace: true }),
);

/**
 * @deprecated
 * @see Use `import { Paragraph } from 'upbound-frontend-elements'` instead.
 */
export const Paragraph = styled('p', { shouldForwardProp })<ParagraphProps>`
  ${props => baseStyle(props.bold || false)};
  color: ${props => COLORS[props.color ?? 'slate']};
  ${props => (props.size ? styleMap[props.size] : null)};
  ${baseStyledSystem};
`;
