import { ClassNames, css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import ReactModal from 'react-modal';

import { COLORS } from '../constants/styles';
import { Box } from './Div';
import { Header } from './Header';
import { InlineIcon } from './Icon';
import { Paragraph } from './Paragraph';

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const StyledBox = styled(Box)<{ width?: string }>`
  position: relative;
  width: ${({ width }) => (width ? width : '600px')};
  box-shadow: 0 2px 5px 0 rgba(80, 90, 114, 0.7);
  border-radius: 10px;
  background-color: ${COLORS.white};
  padding: 40px 35px;
  outline: none;
  animation: ${fadeAnimation} 0.5s;
  max-height: 100%;
  overflow: auto;
  margin: 0 auto;
`;

const StyledModal = styled(ReactModal)`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 100%;
  padding: 60px 0;

  &&&& {
    padding-bottom: 60px;
  }
`;

const XCloseBtn = styled(InlineIcon)`
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;

  svg {
    width: 16px;
    height: 16px;
  }
`;

const overlayStyles = css`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(23, 43, 77, 0.45);
  z-index: 100;
`;

interface Props extends ReactModal.Props {
  children?: React.ReactNode;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
  className?: string;
  width?: string;
}

export const Modal: React.FC<Props> = ({
  children,
  className,
  contentLabel,
  isOpen,
  onClose,
  shouldCloseOnOverlayClick,
  subtitle,
  title,
  width,
}) => {
  return (
    <ClassNames>
      {({ css: classNamesCss }) =>
        isOpen && (
          <StyledModal
            isOpen={isOpen}
            onRequestClose={onClose}
            shouldCloseOnOverlayClick={shouldCloseOnOverlayClick ?? true}
            contentLabel={contentLabel}
            overlayClassName={classNamesCss`
              ${overlayStyles}
            `}
            className={className}
          >
            <StyledBox width={width}>
              <XCloseBtn name="xClose" onClick={onClose} data-testid="Modal Close" />
              {!!title && (
                <Header type="h4" mb={subtitle ? 20 : 30}>
                  {title}
                </Header>
              )}
              {!!subtitle && <Paragraph mb={30}>{subtitle}</Paragraph>}
              {children}
            </StyledBox>
          </StyledModal>
        )
      }
    </ClassNames>
  );
};
