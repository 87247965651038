import styled from '@emotion/styled';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { shouldForwardProp } from 'packages/constants';
import * as styledSystem from 'styled-system';

interface SpanIconProps
  extends styledSystem.PaddingProps,
    styledSystem.MarginProps,
    styledSystem.PositionProps,
    styledSystem.TypographyProps {}

const baseStyledSystem = styledSystem.compose(
  styledSystem.flexbox,
  styledSystem.display,
  styledSystem.layout,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
  styledSystem.typography,
);

const StyledSpanIcon = styled('span', { shouldForwardProp })<SpanIconProps>`
  font-size: 1em;
  width: 1em;
  ${baseStyledSystem};
`;

export type IconProps = Omit<
  SpanIconProps & {
    className?: string;
  } & Pick<FontAwesomeIconProps, 'icon' | 'color' | 'spin'>,
  'css'
>;

const Icon: React.FC<IconProps> = ({ icon, color, spin, ...props }) => {
  return (
    <StyledSpanIcon {...props}>
      <FontAwesomeIcon
        icon={icon}
        color={color}
        spin={spin}
        style={{
          maxHeight: '100%',
          maxWidth: '100%',
        }}
      />
    </StyledSpanIcon>
  );
};

export { Icon };
