export const descriptionText = 'A clear and concise description helps. 120 characters maximum.';

export const trialLimitText =
  'You have reached the free trial limit for this feature. Please contact support to extend your trial limits.';

export const repoNameErrorMsg =
  'Repository name must be between 1-100 characters in length, lowercase only, with no repeating dashes. Names also cannot begin or end with a dash.';

export const instanceNameReq =
  'Must be between 1 and 40 characters in length, start with a lowercase letter, end with a lowercase letter or number and can only contain alphanumeric characters and dashes (-).';

export const missingCpMsg = 'Selected control plane could not be found. Please try again.';

export const leaveOrgErrorMsg =
  'You are currently the sole admin of this organization and you must assign another user as the admin before you can leave.';

export const passwordText =
  'Passwords must be between 8 and 60 characters long and shouldn\'t be things like "password" or "abcdef".';

export const invalidInviteMsg =
  'This invitation that you are trying to use is no longer valid. It may have expired or has already been used. You may need to ask the organization admin for a new invite to join the organization.';

export const inviteEmailMismatchMsg =
  'This invitation that you are trying to use is for a different email then the currently logged in user.';

export const trialControlPlaneLimitText =
  'You have reached your control plane limit. Contact Upbound Sales to learn how you can upgrade your tier to remove this restriction.';

export const trialConfigurationsLimitText =
  'You have reached your configuration limit. Contact Upbound Sales to learn how you can upgrade your tier to remove this restriction.';

export const cpNameCharError =
  'Control plane name must: \n' +
  [
    '- contain only lowercase alphanumeric characters',
    '- start with an alphabetic character',
    '- end with an alphanumeric character',
  ].join('\n');

export const cpNameTooShortError = 'Control plane name must be at least two characters';

export const cpNameTooLongError = 'Control plane name cannot be longer than 255 characters';
