import getConfig from 'next/config';

import { ConfigurationTemplate } from '@/@types/models';

export function getApiServer(): string {
  return getConfig().publicRuntimeConfig.API_SERVER;
}

export function getBaseDomain(): string {
  return getConfig().publicRuntimeConfig.BASE_DOMAIN;
}

export function getAccountsURL() {
  return getConfig().publicRuntimeConfig.ACCOUNTS_URL;
}

export function getCfgTemplates(): ConfigurationTemplate[] {
  try {
    return JSON.parse(getConfig().publicRuntimeConfig.CONFIGURATION_TEMPLATES) || [];
  } catch (e) {
    console.error('Failed to parse configuration templates', e);

    return [];
  }
}

export function getPortalHost() {
  return getConfig().publicRuntimeConfig.PORTAL_HOST;
}

export function getProidcIssuer() {
  return getConfig().publicRuntimeConfig.PROIDC_ISSUER;
}

export function getSentryEnvironment() {
  return getConfig().publicRuntimeConfig.SENTRY_ENVIRONMENT;
}
