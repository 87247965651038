import dayJs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayJs.extend(advancedFormat);
dayJs.extend(relativeTime);
dayJs.extend(duration);

export const dateLib = dayJs;
