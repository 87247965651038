import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import * as styledSystem from 'styled-system';

import errorIconSvg from '../assets/dialogicons/error.svg?svgr';
import infoIconSvg from '../assets/dialogicons/info.svg?svgr';
import successIconSvg from '../assets/dialogicons/success.svg?svgr';
import warningIconSvg from '../assets/dialogicons/warning.svg?svgr';
import accountSvg from '../assets/icons/account.svg?svgr';
import arrowUpRightSvg from '../assets/icons/arrow-up-right.svg?svgr';
import arrowsRotateSvg from '../assets/icons/arrows-rotate.svg?svgr';
import backArrowSvg from '../assets/icons/back-arrow.svg?svgr';
import browseSvg from '../assets/icons/browse.svg?svgr';
import caretdownSvg from '../assets/icons/caret-down.svg?svgr';
import checkmarkSvg from '../assets/icons/checkmark-slate.svg?svgr';
import circleCheckSvg from '../assets/icons/circle-check.svg?svgr';
import circleQuestionSvg from '../assets/icons/circle-question.svg?svgr';
import circleXMarkSvg from '../assets/icons/circle-xmark.svg?svgr';
import circleXMarkBorderSvg from '../assets/icons/circle-xmark-border.svg?svgr';
import circledPlusSvg from '../assets/icons/circled-plus.svg?svgr';
import circularArrowSvg from '../assets/icons/circular-arrow.svg?svgr';
import cliSvg from '../assets/icons/cli.svg?svgr';
import clipboardSvg from '../assets/icons/clipboard.svg?svgr';
import codeBranchSvg from '../assets/icons/code-branch.svg?svgr';
import cogSvg from '../assets/icons/cog.svg?svgr';
import commentSvg from '../assets/icons/comment.svg?svgr';
import configEmptySvg from '../assets/icons/config-empty-state.svg?svgr';
import configurationsSvg from '../assets/icons/configurations.svg?svgr';
import consolesSvg from '../assets/icons/consoles.svg?svgr';
import controlPlanesSvg from '../assets/icons/control-planes.svg?svgr';
import copySvg from '../assets/icons/copy.svg?svgr';
import copyDocumentSvg from '../assets/icons/copy-document.svg?svgr';
import cpSvg from '../assets/icons/cp.svg?svgr';
import cpEmptySvg from '../assets/icons/cp-empty-state.svg?svgr';
import createOrgSvg from '../assets/icons/create-org-pop.svg?svgr';
import docsAndSupportSvg from '../assets/icons/docs-and-support.svg?svgr';
import downChevronSvg from '../assets/icons/down-chevron-dark.svg?svgr';
import ellipsisSvg from '../assets/icons/ellipsis.svg?svgr';
import ellipsisFullSvg from '../assets/icons/ellipsis-full.svg?svgr';
import exitSvg from '../assets/icons/exit.svg?svgr';
import expandSvg from '../assets/icons/expand.svg?svgr';
import eyeInvisibleSvg from '../assets/icons/eye-invisible.svg?svgr';
import eyeVisibleSvg from '../assets/icons/eye-visible.svg?svgr';
import githubIcon from '../assets/icons/github.svg?svgr';
import googleIcon from '../assets/icons/google.svg?svgr';
import graphIcon from '../assets/icons/graph.svg?svgr';
import gridIcon from '../assets/icons/grid.svg?svgr';
import hamburgerSvg from '../assets/icons/hamburger.svg?svgr';
import keySvg from '../assets/icons/key.svg?svgr';
import locationDotSvg from '../assets/icons/location-dot.svg?svgr';
import locationDotSlashedSvg from '../assets/icons/location-dot-slashed.svg?svgr';
import lockSvg from '../assets/icons/lock.svg?svgr';
import upboundSvg from '../assets/icons/logo.svg?svgr';
import upboundGraySvg from '../assets/icons/logo-gray.svg?svgr';
import marketplaceSvg from '../assets/icons/marketplace.svg?svgr';
import mcpConnectorSvg from '../assets/icons/mcp-connector.svg?svgr';
import minusWhiteSvg from '../assets/icons/minus-white.svg?svgr';
import myAccountSvg from '../assets/icons/my-account.svg?svgr';
import namespacesSvg from '../assets/icons/namespaces.svg?svgr';
import navSettingsSvg from '../assets/icons/nav-settings.svg?svgr';
import officialCheckmarkSvg from '../assets/icons/official-badge.svg?svgr';
import penToSquareSvg from '../assets/icons/pen-to-square.svg?svgr';
import plusSlateSvg from '../assets/icons/plus-slate.svg?svgr';
import plusWhiteSvg from '../assets/icons/plus-white.svg?svgr';
import providersSvg from '../assets/icons/providers-icon.svg?svgr';
import pullRequestSvg from '../assets/icons/pull-request.svg?svgr';
import pushedSvg from '../assets/icons/pushed.svg?svgr';
import registrySvg from '../assets/icons/registry.svg?svgr';
import reposSvg from '../assets/icons/repositories.svg?svgr';
import resourceMRSvg from '../assets/icons/resources/mr.svg?svgr';
import resourceOfflineSvg from '../assets/icons/resources/offline.svg?svgr';
import resourceOnlineSvg from '../assets/icons/resources/online.svg?svgr';
import resourceUnknownSvg from '../assets/icons/resources/unknown.svg?svgr';
import resourceXRSvg from '../assets/icons/resources/xr.svg?svgr';
import resourceXRCSvg from '../assets/icons/resources/xrc.svg?svgr';
import rightChevronSvg from '../assets/icons/right-chevron.svg?svgr';
import rightChevronWhiteSvg from '../assets/icons/right-chevron-white.svg?svgr';
import robotsSvg from '../assets/icons/robots.svg?svgr';
import searchSvg from '../assets/icons/search.svg?svgr';
import settingsSvg from '../assets/icons/settings.svg?svgr';
import shopSvg from '../assets/icons/shop.svg?svgr';
import sideBarFlipSvg from '../assets/icons/sidebar-flip.svg?svgr';
import signOutSvg from '../assets/icons/sign-out.svg?svgr';
import smallArrowUpRightSvg from '../assets/icons/small-arrow-up-right.svg?svgr';
import spyglassSvg from '../assets/icons/spyglass.svg?svgr';
import starSvg from '../assets/icons/star.svg?svgr';
import starRemoveSvg from '../assets/icons/star-remove.svg?svgr';
import supportSvg from '../assets/icons/support.svg?svgr';
import teamsSvg from '../assets/icons/teams.svg?svgr';
import tokensSvg from '../assets/icons/tokens.svg?svgr';
import trashCanSvg from '../assets/icons/trash-can.svg?svgr';
import userJoinedSvg from '../assets/icons/user-joined.svg?svgr';
import usersSvg from '../assets/icons/users.svg?svgr';
import versionSvg from '../assets/icons/version.svg?svgr';
import wizardCircleSvg from '../assets/icons/wizard-circle.svg?svgr';
import wizardCircleCheckSvg from '../assets/icons/wizard-circle-check.svg?svgr';
import xCloseSvg from '../assets/icons/x-close.svg?svgr';
import { COLORS } from '../constants/styles';
import { Img } from './Img';
import { Span } from './Span';

const IconMap = {
  account: accountSvg,
  arrowUpRight: arrowUpRightSvg,
  arrowsRotate: arrowsRotateSvg,
  backArrow: backArrowSvg,
  browse: browseSvg,
  caretDown: caretdownSvg,
  checkmark: checkmarkSvg,
  circleCheck: circleCheckSvg,
  circleQuestion: circleQuestionSvg,
  circleXMark: circleXMarkSvg,
  circleXMarkBorder: circleXMarkBorderSvg,
  circledPlus: circledPlusSvg,
  cli: cliSvg,
  clipboard: clipboardSvg,
  codeBranch: codeBranchSvg,
  cog: cogSvg,
  cp: cpSvg,
  cpEmpty: cpEmptySvg,
  configEmpty: configEmptySvg,
  copyDocument: copyDocumentSvg,
  circularArrow: circularArrowSvg,
  comment: commentSvg,
  configurations: configurationsSvg,
  consoles: consolesSvg,
  copy: copySvg,
  createOrg: createOrgSvg,
  docsAndSupport: docsAndSupportSvg,
  downChevron: downChevronSvg,
  ellipsis: ellipsisSvg,
  exit: exitSvg,
  ellipsisFull: ellipsisFullSvg,
  hamburger: hamburgerSvg,
  github: githubIcon,
  google: googleIcon,
  graph: graphIcon,
  grid: gridIcon,
  lock: lockSvg,
  locationDot: locationDotSvg,
  locationDotSlashed: locationDotSlashedSvg,
  officialCheckmark: officialCheckmarkSvg,
  marketplace: marketplaceSvg,
  mcpConnector: mcpConnectorSvg,
  minusWhite: minusWhiteSvg,
  myAccount: myAccountSvg,
  namespaces: namespacesSvg,
  navSettings: navSettingsSvg,
  plane: controlPlanesSvg,
  plusWhite: plusWhiteSvg,
  plusSlate: plusSlateSvg,
  providers: providersSvg,
  pullRequest: pullRequestSvg,
  pushed: pushedSvg,
  registry: registrySvg,
  repositories: reposSvg,
  resourceMR: resourceMRSvg,
  resourceXR: resourceXRSvg,
  resourceXRC: resourceXRCSvg,
  resourceOffline: resourceOfflineSvg,
  resourceOnline: resourceOnlineSvg,
  resourceUnknown: resourceUnknownSvg,
  rightChevron: rightChevronSvg,
  rightChevronWhite: rightChevronWhiteSvg,
  robots: robotsSvg,
  search: searchSvg,
  settings: settingsSvg,
  shop: shopSvg,
  sideBarFlip: sideBarFlipSvg,
  signOut: signOutSvg,
  smallArrowUpRight: smallArrowUpRightSvg,
  spyglass: spyglassSvg,
  star: starSvg,
  starRemove: starRemoveSvg,
  support: supportSvg,
  teams: teamsSvg,
  tokens: tokensSvg,
  upbound: upboundSvg,
  upboundGray: upboundGraySvg,
  key: keySvg,
  userJoined: userJoinedSvg,
  users: usersSvg,
  version: versionSvg,
  wizardCircle: wizardCircleSvg,
  wizardCircleCheck: wizardCircleCheckSvg,
  xClose: xCloseSvg,
  trashCan: trashCanSvg,
  penToSquare: penToSquareSvg,
  errorIcon: errorIconSvg,
  infoIcon: infoIconSvg,
  successIcon: successIconSvg,
  warningIcon: warningIconSvg,
  expand: expandSvg,
  eyeVisible: eyeVisibleSvg,
  eyeInvisible: eyeInvisibleSvg,
};

type PackageIconSizes = 10 | 12 | 14 | 16 | 20 | 24 | 48 | 65 | 96;

const packageIcons = (size: PackageIconSizes, circle = true) => {
  const baseStyles = css`
    display: inline-flex;
    height: ${size}px;
    width: ${size}px;
    overflow: hidden;
  `;

  if (!circle) {
    return baseStyles;
  }

  switch (size) {
    case 10:
    case 12:
    case 14:
    case 16:
      return css`
        ${baseStyles}
        border-radius: 4px;
      `;
    case 20:
      return css`
        ${baseStyles}
        border-radius: 4px;
      `;
    case 24:
      return css`
        ${baseStyles};
        border-radius: 6px;
      `;
    case 48:
      return css`
        ${baseStyles};
        border-radius: 12px;
      `;
    default:
    case 65:
      return css`
        ${baseStyles};
        border-radius: 16px;
      `;
    case 96:
      return css`
        ${baseStyles};
        border-radius: 24px;
      `;
  }
};

export type IconName = keyof typeof IconMap;

export const StyledSpan = styled(Span)<{ svgWidth?: number; svgHeight?: number; color?: COLORS }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    ${({ svgWidth }) =>
      svgWidth &&
      css`
        width: ${svgWidth}px;
      `};
    ${({ svgHeight }) =>
      svgHeight &&
      css`
        height: ${svgHeight}px;
      `};
  }

  ${({ color }) =>
    color &&
    css`
      g,
      path,
      circle,
      rect {
        stroke: ${COLORS[color]};
        color: ${COLORS[color]};
      }
    `}
`;

export const InlineIcon: React.FC<
  Omit<styledSystem.LayoutProps, 'height' | 'width'> &
    styledSystem.SpaceProps & {
      alt?: string;
      circle?: boolean;
      className?: string;
      clickable?: boolean;
      color?: COLORS;
      height?: number;
      name: IconName;
      onClick?: (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
      title?: string;
      width?: number;
    }
> = ({ className, color, height: svgHeight, name, width: svgWidth, title, ...props }) => {
  const CurrentIcon = IconMap[name];

  return (
    <StyledSpan className={className} {...props} color={color} svgHeight={svgHeight} svgWidth={svgWidth} title={title}>
      <CurrentIcon />
    </StyledSpan>
  );
};

export const Icon = styled(InlineIcon)<{ iconSize?: PackageIconSizes }>`
  ${({ iconSize = 24 }) => packageIcons(iconSize)};
  display: block;
  pointer-events: none;

  svg {
    ${({ iconSize = 24 }) => packageIcons(iconSize)};
  }
`;

export const ImgIcon = styled(Img)<{ iconSize?: PackageIconSizes }>`
  ${({ iconSize = 24 }) => packageIcons(iconSize)};
  flex-shrink: 0;
`;

export const IconButton = styled('button')`
  cursor: pointer;
  border-color: transparent;
  background-color: transparent;
  padding: 0;

  &:focus {
    outline: none;
  }
`;
