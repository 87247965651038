import styled from '@emotion/styled';
import React from 'react';
import * as styledSystem from 'styled-system';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { COLORS, shouldForwardProp } from '../constants/styles';

export type HeaderTypes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5';

interface HeaderProps
  extends React.HTMLAttributes<HTMLHeadingElement>,
    styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS;
  bold?: boolean;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);

const headers = {
  h1: styled('h1', { shouldForwardProp })<HeaderProps>`
    ${props => (props.bold ? fontAvenirBold : fontAvenir)};
    color: ${props => props.color ?? COLORS.slate};
    font-size: 55px;
    line-height: 70px;
    ${baseStyledSystem};
  `,
  h2: styled('h2', { shouldForwardProp })<HeaderProps>`
    ${props => (props.bold ? fontAvenirBold : fontAvenir)};
    color: ${props => props.color ?? COLORS.slate};
    font-size: 40px;
    line-height: 50px;
    ${baseStyledSystem};
  `,
  h3: styled('h3', { shouldForwardProp })<HeaderProps>`
    ${props => (props.bold === false ? fontAvenir : fontAvenirBold)};
    color: ${props => props.color ?? COLORS.slate};
    font-size: 25px;
    line-height: 35px;
    ${baseStyledSystem};
  `,
  h4: styled('h4', { shouldForwardProp })<HeaderProps>`
    ${props => (props.bold === false ? fontAvenir : fontAvenirBold)};
    color: ${props => props.color ?? COLORS.slate};
    font-size: 20px;
    line-height: 30px;
    ${baseStyledSystem};
  `,
  h5: styled('h5', { shouldForwardProp })<HeaderProps>`
    ${props => (props.bold === false ? fontAvenir : fontAvenirBold)};
    color: ${props => props.color ?? COLORS.slate};
    font-size: 16px;
    line-height: 25px;
    ${baseStyledSystem};
  `,
};

/**
 * @deprecated
 * @see Use `import { Header } from 'upbound-frontend-elements'` instead.
 */
export const Header: React.FC<Omit<HeaderProps & { type: HeaderTypes }, 'css'>> = ({ type, children, ...props }) => {
  const HeaderType = headers[type];

  return <HeaderType {...props}>{children}</HeaderType>;
};
