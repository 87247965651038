import styled from '@emotion/styled';
import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';
import { fontAvenir } from 'upbound-frontend-constants';

import { COLORS } from '../constants/styles';

interface Props extends TooltipProps {
  id: string;
}

const StyledTooltip = styled(ReactTooltip)`
  color: ${COLORS.white};
  ${fontAvenir};
  font-size: 12px;
  line-height: 20px;
  background-color: ${COLORS.slate} !important;
  opacity: 0.95 !important;
  padding: 6px 15px;
  border-radius: 5px;
  max-width: 330px;
  word-break: break-word;

  &.place-top:after,
  &.place-left:after,
  &.place-right:after,
  &.place-bottom:after {
    border: none !important;
  }
`;

/**
 * @deprecated
 * @see import { TooltipContent, Tooltip, TooltipProvider, TooltipTrigger } from 'packages/elements';
 */
export const Tooltip: React.FC<Props> = ({ id, getContent, ...props }) => {
  return <StyledTooltip id={id} getContent={getContent ? getContent : dataTip => dataTip} uuid="urt" {...props} />;
};
