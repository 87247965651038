import 'prismjs/themes/prism.css';
import 'prismjs';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-json';
import 'prismjs/plugins/line-numbers/prism-line-numbers';

import styled from '@emotion/styled';
// eslint-disable-next-line no-duplicate-imports
import Prism from 'prismjs';
import React, { useEffect, useRef } from 'react';

import { COLORS } from '../constants/styles';
import { Code } from './Code';

export const StyledCode = styled(Code)`
  white-space: pre-wrap;
  padding: 5px 5px 5px 0;

  color: ${COLORS.darkBlueGrey};
  > .token {
    &.key.atrule {
      &,
      & + .punctuation {
        color: ${COLORS.darkGreyBlue};
      }
    }
  }
`;

interface PrismCodeProps {
  code: string;
  language: string;
  plugins?: string[];
}

export const PrismCode: React.FC<PrismCodeProps> = ({ code, language, plugins }) => {
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeRef.current) {
      Prism.highlightElement(codeRef.current);
    }
  }, [codeRef.current, code]);

  return (
    <pre className={!plugins ? '' : plugins.join(' ')}>
      <StyledCode ref={codeRef} className={`language-${language}`}>
        {code.trim()}
      </StyledCode>
    </pre>
  );
};
