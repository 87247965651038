import styled from '@emotion/styled';
import React, { ComponentType, PropsWithChildren } from 'react';
import { twMerge } from 'tailwind-merge';
import { COLORS as SHARED_COLORS } from 'upbound-frontend-constants';

import { COLORS, INNER_CONTENT_PADDING, MAX_GRID_SIZE } from '@/constants/styles';
import { Flex } from '@/elements/Div';

import { MainNav } from './MainNav';
import { TrialCountdownTimer } from './TrialCountdownTimer';

const PageWrap = styled(Flex)`
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  background: ${COLORS.aquaHaze};
`;

const PageNav = styled(Flex.withComponent('nav'))`
  height: 60px;
  width: 100%;
  background-color: ${SHARED_COLORS.Neutral[1000]};
  overflow-x: hidden;
  position: sticky;
  z-index: 50;
  top: 0;
  left: 0;
  padding: 5px 30px;
`;

const PageContent = styled(Flex.withComponent('main'))<{ isNavVisible?: boolean }>`
  height: 100%;
  width: 100%;
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
  padding: ${({ isNavVisible }) => {
    if (!isNavVisible) {
      return '0';
    }

    return INNER_CONTENT_PADDING;
  }};

  & > :first-of-type:not(style):not(aside):not(section) {
    max-width: ${MAX_GRID_SIZE};
    width: 100%;
  }
`;

export const PageTemplate: React.FC<
  React.PropsWithChildren<{
    className?: string;
    isNavVisible?: boolean;
    hideRoutes?: boolean;
    navComponent?: ComponentType<PropsWithChildren> | false;
    belowNavComponent?: ComponentType<PropsWithChildren> | false;
  }>
> = ({
  children,
  className,
  isNavVisible,
  navComponent: NavComponent = MainNav,
  hideRoutes = false,
  belowNavComponent: BelowNavComponent = false,
}) => {
  const baseClass = 'bg-neutral-shades-50';

  return (
    <PageWrap>
      <TrialCountdownTimer />
      {!!NavComponent && (
        <PageNav>
          <NavComponent hideRoutes={hideRoutes} />
        </PageNav>
      )}
      {!!BelowNavComponent && <BelowNavComponent />}

      <PageContent className={twMerge(baseClass, className)} isNavVisible={isNavVisible}>
        {children}
      </PageContent>
    </PageWrap>
  );
};
