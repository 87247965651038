import '@fortawesome/fontawesome-svg-core/styles.css';

import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';

fontAwesomeConfig.autoAddCss = false;

export default fontAwesomeConfig;

export * from './Icon';

// We cannot export * from all of our supported FA packages because they have overlapping exports
// so we export * from one arbitrary package and explicitly export the rest as needed
export * from '@fortawesome/free-solid-svg-icons';
export {
  faSquare as faSquareReg,
  faCheckSquare as faCheckSquareReg,
  faCircle as faCircleReg,
} from '@fortawesome/free-regular-svg-icons';
export { faGithub } from '@fortawesome/free-brands-svg-icons';
export { faGrid2, faBadgeCheck, faSlashForward, faTriangle } from '@fortawesome/pro-solid-svg-icons';
