import { applyArrayIsLastElement } from './isLastElement';
import { applyResizeObserverPolyfill } from './reactObserver';

export const applyPolyfillsGlobally = () => {
  applyArrayIsLastElement();
};

export const applyPolyfillsForTests = () => {
  applyArrayIsLastElement();
  applyResizeObserverPolyfill();
};
