import { z } from 'zod';

import { api } from './shared';

const roleSchema = z.enum(['editor', 'admin', 'viewer']);
export type Role = z.infer<typeof roleSchema>;

const ObjectRoleBindingsList = z.object({
  items: z
    .object({
      metadata: z.object({ namespace: z.string(), name: z.string(), resourceVersion: z.string() }),
      spec: z.object({
        object: z
          .object({
            apiGroup: z.literal('spaces.upbound.io'),
            resource: z.literal('controlplanes'),
            name: z.string(),
          })
          .or(
            z.object({
              apiGroup: z.literal('core'),
              resource: z.literal('namespaces'),
              name: z.string(),
            }),
          ),
        subjects: z.object({ kind: z.literal('UpboundTeam'), name: z.string(), role: roleSchema }).array(),
      }),
    })
    .array(),
});

export async function getControlPlaneAccessManagement(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
        groupName,
      )}/objectrolebindings?labelSelector=authorization.spaces.upbound.io/object-apigroup=spaces.upbound.io,` +
        `authorization.spaces.upbound.io/object-resource=controlplanes,` +
        `authorization.spaces.upbound.io/object-name=${encodeURIComponent(controlPlaneName)}`,
    )
    .then(r => r.data);

  const parsed = ObjectRoleBindingsList.parse(res);

  if (parsed.items.length === 0) {
    return null;
  } else {
    // TODO: What if there is more than one?
    return parsed.items[0];
  }
}

export async function setControlPlaneAccessManagement(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  teamIds: string[],
  role: Role,
): Promise<void> {
  const existingBinding = await getControlPlaneAccessManagement(orgName, spaceName, groupName, controlPlaneName);

  if (existingBinding) {
    // Update binding

    const teamIdsThatNeedChanges = teamIds.filter(
      teamId =>
        !existingBinding.spec.subjects.find(s => s.kind === 'UpboundTeam' && s.name === teamId && s.role === role),
    );

    if (teamIdsThatNeedChanges.length > 0) {
      return api()
        .patch(
          `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
            spaceName,
          )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
            groupName,
          )}/objectrolebindings/${encodeURIComponent(existingBinding.metadata.name)}`,
          {
            metadata: {
              resourceVersion: existingBinding.metadata.resourceVersion,
            },
            spec: {
              subjects: [
                ...existingBinding.spec.subjects.filter(s => s.kind !== 'UpboundTeam' || !teamIds.includes(s.name)),
                ...teamIds.map(teamId => ({ kind: 'UpboundTeam', name: teamId, role })),
              ],
            },
          },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        .then(() => undefined);
    } else {
      // Already set, doing nothing
    }
  } else {
    // Create a whole new binding
    return api()
      .post(
        `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
          spaceName,
        )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
          groupName,
        )}/objectrolebindings`,
        {
          apiVersion: 'authorization.spaces.upbound.io/v1alpha1',
          kind: 'ObjectRoleBinding',
          metadata: {
            generateName: controlPlaneName + '-',
            namespace: groupName,
            labels: {
              'authorization.spaces.upbound.io/object-apigroup': 'spaces.upbound.io',
              'authorization.spaces.upbound.io/object-resource': 'controlplanes',
              'authorization.spaces.upbound.io/object-name': controlPlaneName,
            },
          },
          spec: {
            object: { apiGroup: 'spaces.upbound.io', resource: 'namespaces', name: groupName },
            subjects: teamIds.map(teamId => ({ kind: 'UpboundTeam', name: teamId, role })),
          },
        },
      )
      .then(() => undefined);
  }
}

export async function clearControlPlaneAccessManagement(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  teamId: string,
): Promise<void> {
  const existingBinding = await getControlPlaneAccessManagement(orgName, spaceName, groupName, controlPlaneName);

  if (existingBinding) {
    // Update binding
    if (existingBinding.spec.subjects.find(s => s.kind === 'UpboundTeam' && s.name === teamId)) {
      return api()
        .patch(
          `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
            spaceName,
          )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
            groupName,
          )}/objectrolebindings/${encodeURIComponent(existingBinding.metadata.name)}`,
          {
            metadata: { resourceVersion: existingBinding.metadata.resourceVersion },
            spec: {
              subjects: existingBinding.spec.subjects.filter(s => s.kind !== 'UpboundTeam' || s.name !== teamId),
            },
          },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        .then(() => undefined);
    } else {
      // Not found, doing nothing
    }
  } else {
    // No binding, do nothing
  }
}

export async function getGroupAccessManagement(orgName: string, spaceName: string, groupName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
        groupName,
      )}/objectrolebindings?labelSelector=${encodeURIComponent(
        'authorization.spaces.upbound.io/object-apigroup=core',
      )},${encodeURIComponent('authorization.spaces.upbound.io/object-resource=namespaces')},${encodeURIComponent(
        'authorization.spaces.upbound.io/object-name=' + groupName,
      )}`,
    )
    .then(r => r.data);

  const parsed = ObjectRoleBindingsList.parse(res);

  if (parsed.items.length === 0) {
    return null;
  } else {
    // TODO: What if there is more than one?
    return parsed.items[0];
  }
}

export async function setGroupAccessManagement(
  orgName: string,
  spaceName: string,
  groupName: string,
  teamIds: string[],
  role: 'editor' | 'admin' | 'viewer',
): Promise<void> {
  const existingBinding = await getGroupAccessManagement(orgName, spaceName, groupName);

  if (existingBinding) {
    // Update binding

    const teamIdsThatNeedChanges = teamIds.filter(
      teamId =>
        !existingBinding.spec.subjects.find(s => s.kind === 'UpboundTeam' && s.name === teamId && s.role === role),
    );

    if (teamIdsThatNeedChanges.length > 0) {
      return api()
        .patch(
          `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
            spaceName,
          )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
            groupName,
          )}/objectrolebindings/${encodeURIComponent(existingBinding.metadata.name)}`,
          {
            metadata: { resourceVersion: existingBinding.metadata.resourceVersion },
            spec: {
              subjects: [
                ...existingBinding.spec.subjects.filter(s => s.kind !== 'UpboundTeam' || !teamIds.includes(s.name)),
                ...teamIds.map(teamId => ({ kind: 'UpboundTeam', name: teamId, role })),
              ],
            },
          },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        .then(() => undefined);
    } else {
      // Already set, doing nothing
    }
  } else {
    // Create a whole new binding
    return api()
      .post(
        `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
          spaceName,
        )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
          groupName,
        )}/objectrolebindings`,
        {
          apiVersion: 'authorization.spaces.upbound.io/v1alpha1',
          kind: 'ObjectRoleBinding',
          metadata: {
            generateName: groupName + '-',
            namespace: groupName,
            labels: {
              'authorization.spaces.upbound.io/object-apigroup': 'core',
              'authorization.spaces.upbound.io/object-resource': 'namespaces',
              'authorization.spaces.upbound.io/object-name': groupName,
            },
          },
          spec: {
            object: { apiGroup: 'core', resource: 'namespaces', name: groupName },
            subjects: teamIds.map(teamId => ({ kind: 'UpboundTeam', name: teamId, role })),
          },
        },
      )
      .then(() => undefined);
  }
}

export async function clearGroupAccessManagement(
  orgName: string,
  spaceName: string,
  groupName: string,
  teamId: string,
): Promise<void> {
  const existingBinding = await getGroupAccessManagement(orgName, spaceName, groupName);

  if (existingBinding) {
    // Update binding
    if (existingBinding.spec.subjects.find(s => s.kind === 'UpboundTeam' && s.name === teamId)) {
      return api()
        .patch(
          `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
            spaceName,
          )}/apis/authorization.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
            groupName,
          )}/objectrolebindings/${encodeURIComponent(existingBinding.metadata.name)}`,
          {
            metadata: { resourceVersion: existingBinding.metadata.resourceVersion },
            spec: {
              subjects: existingBinding.spec.subjects.filter(s => s.kind !== 'UpboundTeam' || s.name !== teamId),
            },
          },
          { headers: { 'Content-Type': 'application/merge-patch+json' } },
        )
        .then(() => undefined);
    } else {
      // Not found, doing nothing
    }
  } else {
    // No binding, do nothing
  }
}

const selfSubjectAccessReviewTypes = {
  namespaces: { group: '', resource: 'namespaces' },
  objectRoleBindings: { group: 'authorization.spaces.upbound.io', resource: 'objectrolebindings' },
  controlPlanes: { group: 'spaces.upbound.io', resource: 'controlplanes' },
};

export type SelfSubjectAccessReviewType = keyof typeof selfSubjectAccessReviewTypes;

const SelfSubjectAccessReviewResponse = z.object({ status: z.object({ allowed: z.boolean() }) });

export type SelfSubjectAccessReviewPayload = {
  type: SelfSubjectAccessReviewType;
  verb: string;
  namespace?: string;
  name?: string;
};

export async function selfSubjectAccessReview(
  orgName: string,
  spaceName: string,
  { type, verb, namespace, name }: SelfSubjectAccessReviewPayload,
) {
  const { group, resource } = selfSubjectAccessReviewTypes[type];
  const res = await api()
    .post<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/authorization.k8s.io/v1/selfsubjectaccessreviews`,
      {
        kind: 'SelfSubjectAccessReview',
        apiVersion: 'authorization.k8s.io/v1',
        spec: {
          resourceAttributes: {
            verb,
            group,
            resource,
            namespace: namespace ?? '',
            name: name ?? '',
          },
        },
      },
    )
    .then(r => r.data);

  return SelfSubjectAccessReviewResponse.parse(res).status.allowed;
}
