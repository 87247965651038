import { motion } from 'framer-motion';
import Link from 'next/link';
import {
  Button,
  ButtonLink,
  faClose,
  faExternalLink,
  faList,
  faTriangle,
  Header,
  Icon,
  Panel,
  Paragraph,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'packages/elements';
import React, { useEffect, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import {
  docsCli,
  docsCtpPortal,
  docsGitops,
  docsHome,
  docsMcpManagement,
  docsMcpOIDC,
  marketplaceBrowseConfigurations,
} from '@/constants/links';
import { SESSION_STORE_COUNTDOWN_TIMER_KEY } from '@/constants/session';
import { useGetCurrentAccount } from '@/graphql/reactiveVars';
import { cloud } from '@/routes';
import { useRouterParams } from '@/utils/hooks';
import { IntercomOpen } from '@/utils/intercom';
import { session } from '@/utils/storage';

const TrialGettingStartedDrawer: React.FC<{
  setIsDrawerOpen: (isOpen: boolean) => void;
}> = ({ setIsDrawerOpen }) => {
  const onClose = () => {
    setIsDrawerOpen(false);
  };
  const { orgName, spaceName, groupName } = useRouterParams([
    'orgName',
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
    {
      name: 'groupName',
      disableThrowOnMissing: true,
    },
  ] as const);
  const panelHeaderClasses = 'text-purple-600 text-sm font-extrabold flex items-center space-x-2';
  const panelHeaderNumberClasses =
    'rounded-full w-5 h-5 flex text-neutral-0 bg-purple-600 text-center items-center justify-center font-normal text-xs';
  const panelBodyClasses = 'font-normal text-neutral-600 text-xs text-justify';

  return (
    <motion.div
      variants={{ closed: { opacity: 0 }, open: { opacity: 1 } }}
      initial="open"
      animate="open"
      exit="closed"
      id="trial-getting-started-drawer-overlay"
      data-testid="trial-getting-started-drawer-overlay"
      onClick={onClose}
      className="fixed inset-0 bg-[rgba(23,43,77,0.45)] z-[100]"
    >
      <motion.article
        className="flex flex-col absolute right-0 top-0 bg-neutral-0 max-w-[605px] w-full h-screen overflow-y-auto overflow-x-hidden transition-right duration-1000"
        variants={{ closed: { x: '980px' }, open: { x: '0' } }}
        transition={{ type: 'spring', stiffness: 150, damping: 30 }}
        onClick={e => e.stopPropagation()}
        data-testid={`space-filter-drawer`}
        initial="closed"
        animate="open"
        exit="closed"
      >
        <Button
          btnType="Secondary"
          onClick={onClose}
          className="focus-visible:outline-none absolute right-4 top-4 w-10 z-[102]"
        >
          <Icon icon={faClose} />
        </Button>

        <header className="z-[101] sticky top-0 border-b border-solid border-b-neutral-shades-150 p-8 space-y-3 bg-neutral-0">
          <Header type="h3">Getting Started Guide 👋</Header>
          <p className="text-neutral-600 text-sm font-normal">
            Welcome to your free trial of Upbound. Reference this getting started guide to learn how to get your new
            Upbound account up and running.
          </p>
          <Link
            href={docsHome.url()}
            target="_blank"
            rel="noopener noreferrer"
            className="text-purple-600 text-sm font-normal block hover:underline"
          >
            Read the Upbound Documentation for more information <Icon icon={faExternalLink} />
          </Link>
        </header>

        <div className="p-8 bg-neutral-shades-50 flex-1 space-y-4">
          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>1</span>
              <span>Create your first managed control plane</span>
            </p>
            <p className={panelBodyClasses}>
              Managed control planes (MCPs) are Crossplane control plane environments that are fully managed by Upbound.
              Upbound manages the underlying lifecycle of infrastructure hosting the managed control plane, scaling of
              the infrastructure and maintenance of the MCP’s core Crossplane components.{' '}
              <Link
                href={docsMcpManagement.url()}
                target="_blank"
                rel="noopener noreferrer"
                className="text-purple-600 text-xs font-normal inline-block hover:underline"
              >
                Learn more about managed control planes
                <Icon icon={faExternalLink} className="mx-1" />.
              </Link>
            </p>
            {!!spaceName && !!groupName && (
              <ButtonLink
                btnType="Secondary"
                btnSize="Small"
                href={cloud.spaceGroupCpCreate.url(orgName, spaceName, groupName)}
              >
                Create Managed Control Plane
              </ButtonLink>
            )}
          </Panel>

          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>2</span>
              <span>Install the Up CLI</span>
            </p>
            <p className={panelBodyClasses}>
              The up command-line enables interaction with your Upbound managed control planes. It also simplifies
              common workflows with Upbound Universal Crossplane (UXP) and building Crossplane packages for the Upbound
              Marketplace or any OCI-compliant registry.
            </p>
            <ButtonLink
              btnType="Secondary"
              btnSize="Small"
              href={docsCli.url()}
              target="_blank"
              rel="noopener noreferrer"
            >
              CLI Install Instructions <Icon icon={faExternalLink} className="ml-2" />
            </ButtonLink>
          </Panel>

          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>3</span>
              <span>Try a configuration in the Upbound Marketplace</span>
            </p>
            <p className={panelBodyClasses}>
              Choose a configuration from the marketplace to run on your control plane. Packages with the “Try on
              Upbound” badge can be easily installed in your trial control plane.
            </p>
            <ButtonLink
              btnType="Secondary"
              btnSize="Small"
              href={marketplaceBrowseConfigurations.url()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Explore Marketplace Configurations <Icon icon={faExternalLink} className="ml-2" />
            </ButtonLink>
          </Panel>

          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>4</span>
              <span>Connect your cloud provider</span>
            </p>
            <p className={panelBodyClasses}>
              Connect your control plane to your cloud provider, and use it to create and manage infrastructure. Upbound
              easily allows you to connect to your cloud provider with OpenID Connect.
            </p>
            <ButtonLink
              btnType="Secondary"
              btnSize="Small"
              href={docsMcpOIDC.url()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Connect Cloud Provider Instructions <Icon icon={faExternalLink} className="ml-2" />
            </ButtonLink>
          </Panel>

          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>5</span>
              <span>Create your first control plane resource</span>
            </p>
            <p className={panelBodyClasses}>
              Try creating your first resource now that your first control plane is running and connected to your cloud
              provider you can A simple way to do this is to use the built in control plane portal available in the
              Upbound Console.
            </p>
            <ButtonLink
              btnType="Secondary"
              btnSize="Small"
              href={docsCtpPortal.url()}
              target="_blank"
              rel="noopener noreferrer"
            >
              Control Plane Portal Instructions <Icon icon={faExternalLink} className="ml-2" />
            </ButtonLink>
          </Panel>

          <Panel className="space-y-3">
            <p className={panelHeaderClasses}>
              <span className={panelHeaderNumberClasses}>6</span>
              <span>Integrate GitOps</span>
              <span className="text-green-600 bg-green-shades-50 rounded text-xs font-normal !ml-auto p-1">
                Advanced
              </span>
            </p>
            <p className={panelBodyClasses}>
              Upbound’s managed control planes are compatible with the GitOps pattern your app devs are already
              following. We strongly recommended you integrate GitOps in the platforms you build on Upbound.
            </p>
            <ButtonLink
              btnType="Secondary"
              btnSize="Small"
              href={docsGitops.url()}
              target="_blank"
              rel="noopener noreferrer"
            >
              GitOps Integration Instructions <Icon icon={faExternalLink} className="ml-2" />
            </ButtonLink>
          </Panel>
        </div>
      </motion.article>
    </motion.div>
  );
};
// Provide a countdown this is updated every second that is broken down by days, hours, minutes, and seconds
const useCountdown = (expiresAt: string | null | undefined) => {
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [intervalHandle, setIntervalHandle] = useState<number>();

  const updateTimeRemaining = () => {
    if (!expiresAt) {
      return;
    }
    const expireDate = new Date(expiresAt).getTime();
    const now = new Date().getTime();
    const remaining = Math.max(0, expireDate - now);
    setTimeRemaining(remaining);

    if (remaining === 0 && intervalHandle) {
      clearInterval(intervalHandle);
    }
  };

  // Start an interval to update the time remaining
  useEffect(() => {
    if (expiresAt) {
      updateTimeRemaining(); // Call right away so there isn't an initial delay
      const countdown = window.setInterval(updateTimeRemaining, 60 * 1000);
      setIntervalHandle(countdown);
      return () => window.clearInterval(countdown);
    }
    return;
  }, [expiresAt]);

  return useMemo(
    () => ({
      timeRemaining,
      days: Math.floor(timeRemaining / (1000 * 60 * 60 * 24)),
      hours: Math.floor((timeRemaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60)),
    }),
    [timeRemaining],
  );
};

export const TrialCountdownTimer: React.FC = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const currentAccount = useGetCurrentAccount();
  const countdown = useCountdown(
    currentAccount.organization.tier === 'free' ? currentAccount.organization.trialEndsAt : null,
  );
  const [hasDismissed, setHasDismissed] = useState(!!session.get(SESSION_STORE_COUNTDOWN_TIMER_KEY));

  const _handleDismissClick = () => {
    session.set(SESSION_STORE_COUNTDOWN_TIMER_KEY, 'true');
    setHasDismissed(true);
  };

  const supportClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    IntercomOpen();
  };

  const timeLeft = countdown.timeRemaining > 0;

  // Don't show if it has been dismissed, there is time left, or we are not in the trial yet
  const showCountdown = hasDismissed || !timeLeft || !currentAccount.organization.allowAccess;

  if (showCountdown) {
    return null;
  }

  const pluralize = (value: number, unit: string) => {
    if (value === 1) {
      return `${value} ${unit}`;
    }
    return `${value} ${unit}s`;
  };

  const handleMouseEnter = () => {
    setIsPopoverOpen(true);
  };

  const handleMouseLeave = () => {
    setIsPopoverOpen(false);
  };

  const onGettingStartedGuideClick = () => {
    setIsPopoverOpen(false);
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      <div className="sticky top-0 left-0 w-full min-h-[35px] bg-purple-primary px-[30px] z-10 flex items-center">
        <div className="space-x-1 mr-1 flex">
          <Paragraph className="!text-neutral-0 !font-extrabold !text-xs">
            {countdown.days > 0 && `${pluralize(countdown.days, 'Day')} `}
            {countdown.days === 0 && countdown.hours > 0 && `${pluralize(countdown.hours, 'Hour')} `}
            {countdown.days === 0 && countdown.hours === 0 && `${pluralize(countdown.minutes, 'Minute')} `}
            remaining of your Upbound free trial.
          </Paragraph>
          <Paragraph className="!text-neutral-0 !font-extrabold !text-xs">{pluralize(countdown.days, 'Day')}</Paragraph>
          <Paragraph className="!text-neutral-0 !font-extrabold !text-xs">
            {pluralize(countdown.hours, 'Hour')}
          </Paragraph>
          <Paragraph className="!text-neutral-0 !font-extrabold !text-xs">
            {pluralize(countdown.minutes, 'Minute')}
          </Paragraph>
          <Paragraph className="!text-neutral-0 !text-xs">
            Extend your trial at any time by contacting
            <Link href="#" onClick={supportClick} rel="noopener noreferrer" title="Support" className="ml-1 underline">
              Upbound Support
            </Link>
            .
          </Paragraph>
        </div>
        <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
          <PopoverTrigger
            onClick={onGettingStartedGuideClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={twMerge(
              'ml-auto !text-neutral-0 cursor-pointer hover:underline !font-extrabold !text-xs flex items-center focus-visible:outline-none',
              isPopoverOpen && 'underline',
            )}
          >
            <Icon icon={faList} className="mr-2" />
            <span>Open Getting Started Guide</span>
          </PopoverTrigger>
          <PopoverContent
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="bg-neutral-0 max-w-[274px] p-3 rounded-md drop-shadow-md mt-[9px] relative mr-1  focus-visible:outline-none"
          >
            <Icon
              icon={faTriangle}
              className="absolute top-0 left-1/2 -translate-x-1/2 -translate-y-[10px] !text-neutral-0 !w-[20px] !h-[20px]"
            />
            <Header type="h6" className="!text-[10px] !font-extrabold">
              Getting Started Guide
            </Header>
            <Paragraph className="!text-[10px] !text-neutral-600">
              Access the getting started guide from anywhere in the console when you need help.
            </Paragraph>
          </PopoverContent>
        </Popover>
      </div>
      {!!isDrawerOpen && <TrialGettingStartedDrawer setIsDrawerOpen={setIsDrawerOpen} />}
    </>
  );
};
