import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useGetAllAccountsLazyQuery } from '@/generated/upbound-graphql';
import { useCurrentAccount } from '@/graphql/reactiveVars';
import { app, cloud } from '@/routes';
import { dateLib } from '@/utils/dateLib';

import { LoadingSpinner, LoadingSpinnerWrapper } from './LoadingSpinner';

/**
 * Handle forced redirects like showing the trial expired page
 * @param config is a string optionally provided by pages to indicate to the ReRouter what "section"
 *        the current page is in.
 */
export const ReRouter: React.FC<React.PropsWithChildren<{ config: string | undefined }>> = ({ children, config }) => {
  const reRouteTo = useReRouteTo(config);
  const router = useRouter();
  const [currentAccount] = useCurrentAccount();
  const path = router.asPath;
  const searchParams = path.includes('?') ? `?${new URLSearchParams(path.split('?')[1])}` : '';

  if (router.route !== '/_error') {
    if (reRouteTo === undefined) {
      return (
        <LoadingSpinnerWrapper>
          <LoadingSpinner visible={true} size="xl" />
        </LoadingSpinnerWrapper>
      );
    }

    if (reRouteTo === true) {
      if (!currentAccount) {
        return null;
      }
      router.replace(cloud.spacesIndex.url(currentAccount!.id) + searchParams);
      return null;
    }

    if (reRouteTo) {
      router.replace(reRouteTo + searchParams);
      return null;
    }
  }

  return <>{children}</>;
};

/// Calculate a redirect to force if needed
/// Returns a boolean or a string or undefined:
/// - if it is undefined is indicates that we do not yet know and things are still loading.
/// - if it is a boolean it indicates a redirect is needed or not where true means redirect to the account in general.
/// - if it is a url string it indicates a redirect to that path is needed.
export function useReRouteTo(route: string | undefined): string | boolean | undefined {
  const [currentAccount] = useCurrentAccount();

  const isPortal = window.location.hostname.match(/^[^.]+\.portal/);
  const isError = route === 'error';
  const isRoot = route === 'root';
  const isSelectOrgRoute = route === 'selectOrg';
  const isTrialExpiredRoute = route === 'trialExpired';
  const isStartTrialRoute = route === 'startTrial';
  const isTrialInitiatingRoute = route === 'trialInitiating';

  const { needs: needsOrg, loading: needsOrgLoading } = useNeedsOrg(isRoot || isSelectOrgRoute);
  const trialState = useTrialState(isRoot || isSelectOrgRoute || needsOrgLoading || needsOrg);

  if (isPortal || isError) {
    return false;
  }

  if (isRoot) {
    return app.selectOrg.url();
  }

  if (needsOrgLoading) {
    return undefined;
  }

  // Force org selection if needed
  if (needsOrg) {
    return app.selectOrg.url();
  }

  // Force trial expired if needed
  if (trialState === TrialState.TrialHasExpired) {
    if (!isTrialExpiredRoute) {
      return app.trialExpired.url(currentAccount!.id);
    }
  } else if (isTrialExpiredRoute) {
    // Redirect away if trial not expired
    return true;
  }

  // Force trial not started
  if (trialState === TrialState.TrialNotStarted) {
    if (!isStartTrialRoute) {
      return app.startTrial.url(currentAccount!.id);
    }
  } else if (isStartTrialRoute) {
    // Redirect away if trial start not needed
    return true;
  }

  // Force trial initiating page
  if (trialState === TrialState.ProcessingTrial) {
    if (!isTrialInitiatingRoute) {
      return app.trialInitiating.url(currentAccount!.id);
    }
  } else if (isTrialInitiatingRoute) {
    return true;
  }

  return false;
}

// Determine if we need to select an org
// If the currentAccount is an org we don't need to select an org.
// Otherwise verifies the account in the url is an org if there is one and selects it.
// Otherwise we need to select an org
// NOTE: This will update currentAccount when it changes in the apollo cache or the url account changes.
function useNeedsOrg(disabled: boolean) {
  const router = useRouter();
  const account = router.query.orgName as string | undefined;
  const [currentAccount, setCurrentAccount] = useCurrentAccount();
  const [getAccountsData, { data, called }] = useGetAllAccountsLazyQuery();
  const needed = !disabled && currentAccount?.__typename !== 'OrgAccount';
  const [toReturn, setToReturn] = useState({ needs: false, loading: needed });
  const foundAccount = account && data?.accounts.find(a => a.__typename === 'OrgAccount' && a.id === account);

  useEffect(() => {
    if (!disabled && account) {
      if (foundAccount) {
        if (foundAccount === currentAccount) {
          setToReturn({ needs: needed, loading: false });
        } else {
          setCurrentAccount(foundAccount);
        }
      } else if (!called) {
        getAccountsData();
        setToReturn({ needs: needed, loading: true });
        return;
      } else if (data) {
        setToReturn({ needs: needed, loading: false });
      }
    } else {
      setToReturn({ needs: needed, loading: false });
    }
  }, [account, called, currentAccount, data, disabled, foundAccount, getAccountsData, needed, setCurrentAccount]);

  // If url account does not equal selected account we are loading and it will
  // be handled in the useEffect above
  if (account && account !== currentAccount?.id) {
    return { needs: false, loading: true };
  }

  return toReturn;
}

/// The state of trial an organization is in
export enum TrialState {
  /// No trial state is needed (in a paid tier) or is unknown at this point
  Ignore,
  /// Will need a trial to continue but has not yet begun one
  TrialNotStarted,
  /// Currently in a non-expired trial
  InTrial,
  /// Has completed a trial but it has expired
  TrialHasExpired,
  /// The user has initiated a trial but we are still determining if we have capacity or do not yet have capacity
  ProcessingTrial,
}

/// Calculate if what state we are in with respect to a trial
export function useTrialState(disable: boolean): TrialState {
  const [currentAccount] = useCurrentAccount();
  if (disable || currentAccount?.__typename !== 'OrgAccount' || currentAccount.organization.tier !== 'free') {
    return TrialState.Ignore;
  }

  const trialStart = currentAccount.organization.trialStartedAt
    ? dateLib(currentAccount.organization.trialStartedAt)
    : undefined;
  const trialEnd = currentAccount.organization.trialEndsAt
    ? dateLib(currentAccount.organization.trialEndsAt)
    : undefined;

  if (trialStart && trialEnd) {
    if (trialEnd < dateLib()) {
      return TrialState.TrialHasExpired;
    }

    if (!currentAccount.organization.allowAccess) {
      return TrialState.ProcessingTrial;
    }

    return TrialState.InTrial;
  }

  return TrialState.TrialNotStarted;
}
