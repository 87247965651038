import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { TableData, TableHookOptions } from './types';
import { useState } from 'react';

export const useTableHook = <T>(options: TableHookOptions<T>): ReturnType<typeof useReactTable<T>> & TableData<T> => {
  const table = useReactTable({
    // Row Model represents list of all rows to be rendered, as well as HOW they should be rendered
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...options,
  });

  return {
    ...table,
    headerGroups: table.getHeaderGroups(),
    tableRows: table.getRowModel().rows,
  };
};

export const usePagination = ({ pageSize, pageIndex }: PaginationState) =>
  useState<PaginationState>({
    pageSize,
    pageIndex,
  });

export { createColumnHelper, getPaginationRowModel, flexRender };
