import { generatePath } from '.';

export const root = {
  url() {
    return root.route;
  },
  route: '/' as const,
};

export const fiveHundred = {
  url() {
    return fiveHundred.route;
  },
  route: '/500' as const,
};

export const fourOhFour = {
  url() {
    return fourOhFour.route;
  },
  route: '/404' as const,
};

export const privatePreview = {
  url() {
    return privatePreview.route;
  },
  route: '/private-preview' as const,
};

export const selectOrg = {
  url() {
    return selectOrg.route;
  },
  route: '/selectOrg' as const,
};

export const org = {
  url(account: string) {
    return generatePath(org.route, { account });
  },
  route: '/:account' as const,
};

export const startTrial = {
  url(account: string) {
    return generatePath(startTrial.route, { account });
  },
  route: '/:account/startTrial' as const,
};

export const trialExpired = {
  url(account: string) {
    return generatePath(trialExpired.route, { account });
  },
  route: '/:account/trialExpired' as const,
};

export const trialInitiating = {
  url(account: string) {
    return generatePath(trialInitiating.route, { account });
  },
  route: '/:account/trialInitiating' as const,
};

export const adminRequired = {
  url(account: string, spaceName: string) {
    return generatePath(adminRequired.route, { account, spaceName });
  },
  route: '/:account/spaces/:spaceName/adminRequired' as const,
};
