/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { toast, ToastContentProps, ToastOptions, ToastPosition, TypeOptions } from 'react-toastify';

import { LoadingSpinner } from '../components/LoadingSpinner';
import { COLORS, MAX_GRID_SIZE } from '../constants/styles';
import { Button, LinkButton } from './Button';
import { Flex } from './Div';

interface ToastProps {
  closeToast: () => void;
}

interface Props {
  dismissText?: string;
  extraButton?: {
    link: string;
    text: string;
  };
  forceDismiss?: React.ReactText | boolean;
  hasLoader?: boolean;
  msg?: string;
  /** Optional @timeout  - number in ms to wait before hiding DialogBox if animated is true */
  timeout?: number;

  /** Optional @type - we only currently show one style of toast but this will be useful if that changes */
  type?: TypeOptions;

  position?: ToastPosition;
}

const btnStyle = css`
  color: ${COLORS.white};
  border: solid 1px ${COLORS.white};
  background-color: transparent;
  margin-left: 0 !important;
`;

const StyledButton = styled(Button)`
  ${btnStyle}
`;

export const Toast = ({
  dismissText,
  extraButton,
  forceDismiss,
  hasLoader,
  msg = '',
  timeout = 5000,
  position = 'bottom-center',
}: Props) => {
  const maxToastWidth = `${parseInt(MAX_GRID_SIZE, 10) - 48}px`;

  toast.clearWaitingQueue();
  toast.dismiss();

  if (forceDismiss) {
    toast.dismiss(typeof forceDismiss === 'boolean' ? undefined : forceDismiss);
    return;
  }

  if (!msg) {
    return;
  }

  const Message = ({ closeToast }: ToastProps) => (
    <Flex alignItems="center" maxWidth={maxToastWidth}>
      {!!hasLoader && <LoadingSpinner color="white" inButton={true} size="s" visible={true} />}
      <Flex flex="1" mr={20} ml={hasLoader ? 20 : undefined} textAlign="left">
        {msg}
      </Flex>
      {!!extraButton && (
        <LinkButton href={extraButton.link} onClick={closeToast} size="small" mr={5.5} css={btnStyle}>
          {extraButton.text}
        </LinkButton>
      )}
      {!extraButton && (
        <StyledButton size="small" onClick={closeToast}>
          {dismissText || 'Dismiss'}
        </StyledButton>
      )}
    </Flex>
  );

  const toastOptions: ToastOptions = {
    autoClose: typeof extraButton !== 'undefined' ? false : timeout,
    className: 'dismissable',
    closeOnClick: false,
    position,
    icon: false,
  };

  return toast.success(
    ({ closeToast }: ToastContentProps) => <Message closeToast={closeToast ?? (() => undefined)} />,
    toastOptions,
  );
};
