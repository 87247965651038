import styled from '@emotion/styled';
import * as styledSystem from 'styled-system';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { COLORS, shouldForwardProp } from '../constants/styles';

interface SpanProps
  extends styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: keyof typeof COLORS;
  bold?: boolean;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);
/**
 * @deprecated
 * @see Use `import { Span } from 'upbound-frontend-elements'` instead.
 * */
export const Span = styled('span', { shouldForwardProp })<SpanProps>`
  ${({ bold }) => (bold ? fontAvenirBold : fontAvenir)}
  color: ${props => props.color ?? COLORS.slate};
  ${baseStyledSystem}
`;
