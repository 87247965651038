import styled from '@emotion/styled';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { COLORS as SHARED_COLORS } from 'upbound-frontend-constants';

import { PopoverContainer, PopoverRow } from '@/components/Popovers';
import { accountsLogout } from '@/constants/links';
import { COLORS, TIMING } from '@/constants/styles';
import { Anchor } from '@/elements/Anchor';
import { AnchorButton, Button } from '@/elements/Button';
import { Box, Flex } from '@/elements/Div';
import { Header } from '@/elements/Header';
import { InlineIcon } from '@/elements/Icon';
import { Modal } from '@/elements/Modal';
import { Paragraph } from '@/elements/Paragraph';
import { Popover } from '@/elements/Popover';
import { Span } from '@/elements/Span';
import { useGetAllAccountsQuery } from '@/generated/upbound-graphql';
import { useAllAccounts, useCurrentUser, useGetCurrentAccount } from '@/graphql/reactiveVars';
import { app, external } from '@/routes';
import { notEmpty } from '@/utils/helpers';
import { useRedirect } from '@/utils/hooks';
import { IntercomOpen } from '@/utils/intercom';

import { AvatarPlaceholder, DisplayNameSpan, DisplayOrgSpan } from './AvatarPlaceholder';

const StyledPopoverContainer = styled(PopoverContainer)`
  width: 250px;
  padding: 10px 0;
  border-radius: 10px;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.16);
`;

const PopoverRowContent = styled(Flex)``;

const OrgActionsWrap = styled(Box)<{ noTop?: boolean }>`
  border-top: ${({ noTop }) => (noTop ?? false ? '0' : '1px')} solid ${COLORS.lightPeriwinkle};
  margin-top: ${({ noTop }) => (noTop ?? false ? '0' : '10px')};
  padding-top: ${({ noTop }) => (noTop ?? false ? '0' : '4px')};

  dt {
    padding: 7px 15px 5px 15px;
    font-size: 14px;
    line-height: 20px;
    color: ${COLORS.fillBlackBlack};
  }
`;

const UserButton = styled(Flex)<{ isAccountPopoverOpen?: boolean }>`
  align-items: center;
  cursor: pointer;
  pointer-events: auto;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0);
  transition: background ${TIMING.fast()} ease-in-out;

  & > * {
    pointer-events: none;
  }

  &:hover {
    background: ${SHARED_COLORS.Neutral[1000]};
  }
  ${({ isAccountPopoverOpen }) => (isAccountPopoverOpen ? SHARED_COLORS.Neutral[1000] : '')}
`;

const AccountInfoContainer = styled(Flex)`
  pointer-events: none;
`;

const DisplayEmailSpan = styled(Span)`
  font-size: 14px;
  line-height: 20px;
`;

export const AccountPopover: React.FC = () => {
  const [allAccounts, setAllAccounts] = useAllAccounts();
  const currentAccount = useGetCurrentAccount();
  const currentUser = useCurrentUser();
  const redirect = useRedirect();
  const router = useRouter();

  const [isAccountPopoverOpen, setAccountPopoverOpen] = useState(false);
  const [isLimitReachedOpen, setLimitReachedOpen] = useState(false);

  const { data: accountData } = useGetAllAccountsQuery();

  useEffect(() => {
    setAccountPopoverOpen(false);
    setLimitReachedOpen(false);
  }, [router.pathname]);

  useEffect(() => {
    if (!accountData) {
      return;
    }
    setAllAccounts(accountData.accounts);
  }, [accountData]);

  if (!allAccounts) {
    return null;
  }

  const orgAccounts = allAccounts
    .map(account => (account.__typename === 'OrgAccount' ? account : null))
    .filter(notEmpty);

  const handleClickOutside = (e: MouseEvent) => {
    const targetId = (e.target as HTMLElement)?.id;

    if (targetId === 'user-btn') {
      return;
    }

    setAccountPopoverOpen(false);
  };

  const organization = currentAccount.organization;

  return (
    <>
      <Popover
        isOpen={isAccountPopoverOpen}
        positions={['bottom']}
        align="end"
        onClickOutside={handleClickOutside}
        containerStyle={{
          left: '15px',
          top: '5px',
          pointerEvents: isAccountPopoverOpen ? 'auto' : 'none', // If this popover is closed but still present ignore pointer events
          userSelect: isAccountPopoverOpen ? 'auto' : 'none',
        }}
      >
        <UserButton
          isAccountPopoverOpen={isAccountPopoverOpen}
          id="user-btn"
          data-testid="user-btn"
          onClick={() => {
            setAccountPopoverOpen(!isAccountPopoverOpen);
          }}
        >
          <AccountInfoContainer>
            <AvatarPlaceholder size={30} type="OrgAccount" organization={organization}>
              <DisplayNameSpan color="white">{currentUser.name}</DisplayNameSpan>
              <DisplayOrgSpan color="white">{organization.name}</DisplayOrgSpan>
            </AvatarPlaceholder>
            <InlineIcon name="caretDown" ml="15px" />
          </AccountInfoContainer>
        </UserButton>
        <StyledPopoverContainer data-testid="account-popover">
          <Box width="100%" maxHeight="calc(100vh - 110px)" overflow="auto">
            <>
              <ul>
                <PopoverRow
                  title={`${currentUser.firstName} ${currentUser.lastName}`}
                  key={currentUser.id}
                  style={{ cursor: 'default' }}
                >
                  <PopoverRowContent alignItems="center" justifyContent="space-between">
                    <Flex flexDirection="column">
                      <DisplayNameSpan color="fillBlackBlack">{currentUser.name}</DisplayNameSpan>
                      <DisplayEmailSpan color="comet">{currentUser.email}</DisplayEmailSpan>
                    </Flex>
                  </PopoverRowContent>
                </PopoverRow>
                <PopoverRow>
                  <Anchor href={external.accountsSettings.url(undefined, window.location.href)}>
                    <PopoverRowContent alignItems="center">
                      <InlineIcon name="account" mr="7px" width={16} height={16} />
                      <Span>My Account</Span>
                    </PopoverRowContent>
                  </Anchor>
                </PopoverRow>
                <PopoverRow data-testid="popover-sign-out">
                  <Anchor href={accountsLogout.url(window.location.origin)}>
                    <PopoverRowContent alignItems="center">
                      <InlineIcon name="exit" mr="7px" width={16} height={16} />
                      <Span>Sign Out</Span>
                    </PopoverRowContent>
                  </Anchor>
                </PopoverRow>
              </ul>
            </>
            {!!orgAccounts.length && (
              <OrgActionsWrap pt="5px" mt="5px">
                <dl>
                  <dt>My Organizations</dt>
                  {orgAccounts.map(account => (
                    <PopoverRow
                      key={account.id}
                      as="dd"
                      onClick={() => {
                        redirect(app.org.url(account.id));
                        setAccountPopoverOpen(false);
                      }}
                    >
                      <PopoverRowContent alignItems="center">
                        <AvatarPlaceholder
                          type="OrgAccount"
                          organization={account.organization}
                          size={20}
                          orgNameColor="fillBlackBlack"
                        >
                          <Span>{account.organization.name}</Span>
                        </AvatarPlaceholder>
                      </PopoverRowContent>
                    </PopoverRow>
                  ))}
                </dl>
              </OrgActionsWrap>
            )}
          </Box>
        </StyledPopoverContainer>
      </Popover>
      <Modal shouldCloseOnOverlayClick={true} isOpen={isLimitReachedOpen} onClose={() => setLimitReachedOpen(false)}>
        <Header type="h4" mb={20}>
          Organization Limit Reached
        </Header>
        <Paragraph mb={30}>
          Please upgrade to Upbound Enterprise to create additional organizations. Contact us to learn more.
        </Paragraph>
        <Flex>
          <Button onClick={() => setLimitReachedOpen(false)} btnType="paleGreyOutline" type="button" mr={10}>
            Cancel
          </Button>
          <AnchorButton
            btnType="purpleFill"
            href="#"
            rel="noopener noreferrer"
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              IntercomOpen();
            }}
          >
            Contact Support
          </AnchorButton>
        </Flex>
      </Modal>
    </>
  );
};
