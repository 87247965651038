import axios, { AxiosInstance } from 'axios';

import { getApiServer, getBaseDomain } from '@/utils/config';

let apiInstance: AxiosInstance;
let proxyApiInstance: AxiosInstance;

export const proxyApi = () => {
  if (!proxyApiInstance) {
    // We don't have a local proxy for the mcp-router proxy api, so we need to
    // manually adjust the base domain to point to Dev if on local
    const baseDomain = getBaseDomain().replace('local.', '');

    // We don't intend to rely on the mcp-router proxy api for long term,
    // so rather than create a new, configurable env variable for the proxy domain
    // we've decided to construct the URL using the existing baseDomain var
    const BASE_URL = `https://proxy.${baseDomain}`;

    proxyApiInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  return proxyApiInstance;
};

export const api = () => {
  if (!apiInstance) {
    const BASE_URL = getApiServer();

    apiInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  return apiInstance;
};
