import styled from '@emotion/styled';
import React, { HTMLAttributes, ReactNode } from 'react';
import * as styledSystem from 'styled-system';

import { COLORS, shouldForwardProp } from '../constants/styles';

const whiteSpace = styledSystem.style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
});

export interface BaseProps
  extends HTMLAttributes<HTMLDivElement>,
    styledSystem.BackgroundProps,
    styledSystem.BorderProps,
    styledSystem.ColorProps,
    styledSystem.FlexboxProps,
    styledSystem.LayoutProps,
    styledSystem.OverflowProps,
    styledSystem.PositionProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS;
  whiteSpace?:
    | 'normal'
    | 'nowrap'
    | 'pre'
    | 'pre-wrap'
    | 'pre-line'
    | 'break-spaces'
    | 'inherit'
    | 'initial'
    | 'revert'
    | 'revert-layer'
    | 'unset';
}

interface GridProps extends BaseProps, styledSystem.GridProps {}

const baseStyledSystem = styledSystem.compose(
  styledSystem.background,
  styledSystem.border,
  styledSystem.color,
  styledSystem.flexbox,
  styledSystem.layout,
  styledSystem.overflow,
  styledSystem.position,
  styledSystem.space,
  styledSystem.typography,
  whiteSpace,
);

const Box = styled('div', { shouldForwardProp })<BaseProps>`
  box-sizing: border-box;
  ${baseStyledSystem};
`;

Box.displayName = 'Box';

const Flex = styled('div', { shouldForwardProp })<BaseProps>`
  box-sizing: border-box;
  display: flex;
  ${baseStyledSystem};
`;

Flex.displayName = 'Flex';

const Grid = styled('div', { shouldForwardProp })<GridProps>`
  box-sizing: border-box;
  display: grid;
  ${baseStyledSystem};
  ${styledSystem.grid};
`;

Grid.displayName = 'Grid';

const GridSystem: React.FC<{ children: ReactNode } & Omit<BaseProps, 'css'>> = ({ children, ...props }) => (
  <Flex flexWrap="wrap" {...props}>
    {children}
  </Flex>
);

GridSystem.displayName = 'GridSystem';

const Column = ({
  colSize,
  children,
  ...props
}: Omit<BaseProps, 'css'> & {
  children?: ReactNode;
  colSize: styledSystem.ResponsiveValue<string | styledSystem.TLengthStyledSystem>;
  className?: string | undefined;
}) => (
  <Box flexBasis={colSize} maxWidth={colSize} pl={15} pr={15} pb={15} {...props}>
    {children}
  </Box>
);

Column.displayName = 'Column';

const Separator = styled(Box)`
  height: 1px;
  width: 100%;
  /* todo: utilize COLORS */
  background-color: #e5e6e9;
  grid-column: 1/-1;
`;

export { Column, Grid, Flex, Box, GridSystem, Separator };

export const COL12 = (100 * 12) / 12 + '%';
export const COL11 = (100 * 11) / 12 + '%';
export const COL10 = (100 * 10) / 12 + '%';
export const COL9 = (100 * 9) / 12 + '%';
export const COL8 = (100 * 8) / 12 + '%';
export const COL7 = (100 * 7) / 12 + '%';
export const COL6 = (100 * 6) / 12 + '%';
export const COL5 = (100 * 5) / 12 + '%';
export const COL4 = (100 * 4) / 12 + '%';
export const COL3 = (100 * 3) / 12 + '%';
export const COL2 = (100 * 2) / 12 + '%';
export const COL1 = (100 * 1) / 12 + '%';
